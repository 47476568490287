import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/main',
    component: () => import('@/views/PublicViews/PublicHome.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/home',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/sugerencias',
    component: () => import('@/views/SugerenciasPage.vue')
  },
  {
    path: '/feeling',
    component: () => import('@/views/FeelingsPage.vue')
  },
  {
    path: '/cafe',
    component: () => import('@/views/CafeconLideres.vue')
  },
  {
    path: '/docsrh',
    component: () => import('@/views/DocumentosRH.vue')
  },
  {
    path: '/mentoring',
    component: () => import('@/views/Mentoring.vue')
  },
  {
    path: '/wellness',
    component: () => import('@/views/Wellness.vue')
  },
  {
    path: '/mentoring/card/:id',
    component: () => import('@/views/MentoringCard.vue'),
    props: true
  },
  {
    path: '/AdminRH',
    component: () => import('@/views/AdminRH.vue')
  },
  {
    path: '/AdminRH/usuarios',
    component: () => import('@/components/Pages/AdminRH/Usuarios.vue')
  },
  {
    path: '/AdminRH/news',
    component: () => import('@/components/Pages/AdminRH/Noticias.vue')
  },
  {
    path: '/AdminRH/sugestions',
    component: () => import('@/components/Pages/AdminRH/Sugerencias.vue')
  },
  {
    path: '/cotizacion',
    component: () => import('@/views/PublicViews/Cotizacion.vue')
  },
  {
    path: '/registrate',
    component: () => import('@/views/Registrate.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
